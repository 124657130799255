import { useState, useEffect } from "react";
import { theme } from "../../theme";
import { Button, Checkbox, Form, Input, Select } from "antd";
import nophoto from "../../Media/nophoto.jpg";
import { usePushNotifications, useIsAdmin } from "../../utils/utilFunctions";
import {
	groupPattern,
	phonePattern,
	urlPattern,
	userSkills,
} from "../../utils/constants";
import {
	acceptGDPRDate,
	getStudentById,
	toggleAcademicUserNotifications,
	updateStudentInfoAdmin,
} from "../../Requests/academic-user-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	selectLoading,
	selectStudent,
	selectStudentId,
	studentGetSucceeded,
	studentIdSet,
	studentLoad,
} from "../Student/StudentSlice";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "react-query";
import { getDepartmentsForFaculty } from "../../Requests/department-requests";
import { ErrorCodes } from "../../Api";
import {
	getCurrentStudentStudyProgramAdmin,
	getStudyPrograms,
	setCurrentStudentStudyProgram,
} from "../../Requests/student-study-requests";
import {
	getFacultyDepartments,
	getStudentAccountInfo,
	getStudentCurrentStudyProgram,
	getStudyProgramsInfo,
} from "../../utils/reactQueriesConstants";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ImageCropModal from "./ImageCropModal";
import { getErrorFromResponse } from "../../utils/responseUtils";
import CustomEditor from "../../CustomComponents/CustomEditor";
import PersonalDataModal from "../Student/PersonalDataModal";

const { Option } = Select;

const StudentProfile = (props: { location: any }) => {
	const studentId = useSelector(selectStudentId);
	const { t, i18n } = useTranslation();
	const [state, setState] = useState({} as any);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [profilePicture, setProfilePicture] = useState<string | null>("");
	const [lowResPicture, setLowResPicture] = useState<string | null>("");
	const [cropImageModalVisibility, setCropImageModalVisibility] =
		useState(false);
	const dispatch = useDispatch();
	const [skills, setSkills] = useState<string[]>(new Array<string>());
	const [checked, setChecked] = useState(false);
	const [form] = Form.useForm<{
		phone: string;
		linkedInUrl?: string | null;
		name?: string | null;
		email: string;
		personalEmail: string;
		domainOfActivity?: string | null;
		researchField?: string | null;
		skills?: string[] | null;
		areasOfInterest?: string[] | null;
		description?: string | null;
		gitUrl?: string | null;
		siteUrl?: string | null;
		cvUrl?: string | null;
		department?: string;
		studyProgram?: string;
		group?: string | null;
		preferredLanguage?: string;
		address?: string;
		postalCode?: string;
	}>();
	const isAdmin = useIsAdmin();
	const [spinning, setSpinning] = useState(false);
	const queryClient = useQueryClient();

	const history = useHistory();
	const redirectToProfile = () => {
		dispatch(studentIdSet(studentId!));
		history.push("/student");
	};

	const {
		pushNotificationSupported,
		userSubscription,
		subscribeToPushNotifications,
		unsubscribeFromPushNotifications,
	} = usePushNotifications();

	const onCheckChange = (e: CheckboxChangeEvent) => {
		setChecked(e.target.checked);
		acceptGDPRDate(e.target.checked)
			.then((e) => successMessage())
			.catch((e) => saveChangesError());
	};

	const openDataErrorNotification = (_error: any) => {
		openNotification(
			t("account.error"),
			t("account.fetchUserDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: departmentList } = useQuery(
		[getFacultyDepartments, isAdmin],
		async () => {
			return isAdmin ? await getDepartmentsForFaculty() : null;
		},
		{
			onError: (err) => {
				openDataErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: currentStudyProgram } = useQuery(
		[getStudentCurrentStudyProgram, studentId, isAdmin],
		async () => {
			const result = await getCurrentStudentStudyProgramAdmin(studentId || "");
			return result;
		},
		{
			onSuccess: (response) => {
				if (response) {
					setState({
						...state,
						studyProgram: response.studyProgram?.id,
					});
					form.setFieldsValue({
						...form.getFieldsValue(),
						studyProgram: response.studyProgram?.id,
					} as any);
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: studentState } = useQuery(
		[
			getStudentAccountInfo,
			departmentList,
			isAdmin,
			studentId,
			props.location,
			currentStudyProgram,
		],
		async () => {
			if (isAdmin) return await getStudentById(studentId!);
			return {};
		},
		{
			onSuccess: (response) => {
				if (response) {
					setState(response);
					setChecked(response.hasAcceptedPublicProfile!);
					const responseSkills = response.userSkills?.map((x) => x.skill!);
					form.setFieldsValue({
						...form.getFieldsValue(),
						name: response.name,
						email: response.email || "",
						personalEmail: response.personalEmail || "",
						phone: response.phone || "",
						cvUrl: response.cvUrl,
						gitUrl: response.gitUrl,
						linkedInUrl: response.linkedInUrl,
						description: response.description,
						skills: responseSkills,
						department: departmentList?.find(
							(d) => d.departmentNameEn === response.departmentNameEn
						)?.id,
						group: response.group,
					} as any);
				}
			},
			onError: openDataErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: studyPrograms } = useQuery(
		[getStudyProgramsInfo, studentState],
		async () => await getStudyPrograms(studentState?.facultyId!),
		{
			onError: (err) => {
				openDataErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const saveChangesError = () => {
		openNotification(
			t("account.saveError"),
			t("account.saveChangesError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const missingFieldsError = () => {
		openNotification(
			t("account.saveError"),
			t("usersText.requiredFieldsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const successMessage = () => {
		openNotification(
			t("account.success"),
			t("account.successSaveMessage"),
			NOTIFICATION_TYPES.SUCCESS
		);
	};

	const changeLng = (event: any) => {
		setState({
			...state,
			preferredLanguage: event.toUpperCase(),
		});
	};

	const handleSave = () => {
		form
			.validateFields()
			.then(() => {
				let promise: Promise<void> | null = null;
				setSpinning(true);
				promise = updateStudentInfoAdmin(studentId!, {
					description: state?.description ?? "",
					phone: state?.phone ?? "",
					personalEmail: state?.personalEmail ?? "",
					cvUrl: state?.cvUrl,
					gitUrl: state?.gitUrl,
					linkedInUrl: state?.linkedInUrl ?? "",
					fullResImage: state?.fullResImage,
					lowResImage: state?.lowResImage,
					departmentId: form.getFieldValue("department"),
					group: state?.group ?? "",
					skills,
				});

				setCurrentStudentStudyProgram(form.getFieldValue("studyProgram")).catch(
					saveChangesError
				);

				promise
					?.then(() => {
						successMessage();
						redirectToProfile();
					})
					?.catch(async (error: any) => {
						const errorMessage = await getErrorFromResponse(error);

						let notificationMessage =
							errorMessage?.code === ErrorCodes.Forbidden
								? t("account.cannotUpdateProfile")
								: t("account.saveChangesError");

						openNotification(
							t("account.saveError"),
							notificationMessage,
							NOTIFICATION_TYPES.ERROR
						);
					})
					.finally(() => {
						setSpinning(false);
						setModalVisibility(false);
					});
			})
			.catch(missingFieldsError)
			.finally(() => setModalVisibility(false));
	};

	const handleChangeSkills = (value: any) => {
		setSkills(value);
	};

	const handleChange = (event: any, field: any) => {
		event.persist();
		setState({
			...state,
			[field]: event.target.value,
		});
	};

	const onNotificationToggle = (e: CheckboxChangeEvent) => {
		toggleAcademicUserNotifications()
			.then(async () => {
				await queryClient.invalidateQueries(getStudentAccountInfo);

				if (e.target.checked) {
					openNotification(
						t("account.notificationsEnabled"),
						t("account.notificationsEnabledMessage"),
						NOTIFICATION_TYPES.SUCCESS
					);
				} else {
					openNotification(
						t("account.notificationsDisabled"),
						t("account.notificationsDisabledMessage"),
						NOTIFICATION_TYPES.SUCCESS
					);
				}
			})
			.catch(() => {
				openNotification(
					t("account.error"),
					t("account.unknownError"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const onPushNotificationToggle = async (e: CheckboxChangeEvent) => {
		if (e.target.checked && pushNotificationSupported) {
			await subscribeToPushNotifications();
		} else if (!e.target.checked) {
			await unsubscribeFromPushNotifications();
		}
	};

	useEffect(() => {
		setState({
			...state,
			fullResImage: profilePicture,
			lowResImage: lowResPicture,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profilePicture, lowResPicture]);

	return (
		<div className="flex flex-col">
			<div className="flex gap-20 flex-col items-center lg:flex-row lg:items-start max-w-[728px] py-20 px-4 mx-auto">
				<div className="flex flex-col gap-10">
					<div className="relative">
						<ImageCropModal
							changeProfilePicture={setProfilePicture}
							changeLowResPicture={setLowResPicture}
							modalVisibility={cropImageModalVisibility}
							changeModalVisibility={setCropImageModalVisibility}
						/>

						{state && (
							<img
								src={state?.fullResImage ?? state?.lowResImage ?? nophoto}
								alt="Profile"
								className="w-[172px] h-[172px] rounded-full"
							/>
						)}

						<div className="absolute bottom-0 right-0 flex gap-2 translate-y-1/2">
							<Button
								danger
								onClick={() => {
									setProfilePicture(null);
									setLowResPicture(null);
								}}
								title={t("account.deletePicture")}
							>
								<FontAwesomeIcon icon={solid("trash")} />
							</Button>

							<Button
								type="primary"
								onClick={() => setCropImageModalVisibility(true)}
								title={t("account.changePicture")}
							>
								<FontAwesomeIcon icon={solid("camera")} />
							</Button>
						</div>
					</div>

					<div className="flex flex-col gap-6">
						<Button onClick={history.goBack}>
							{t("profile.backToInternships")}
						</Button>

						<Button
							htmlType="submit"
							style={{
								background: theme.secondColor,
								color: theme.white,
								boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
							}}
							onClick={() => setModalVisibility(true)}
						>
							{t("account.save")}
						</Button>
					</div>

					<PersonalDataModal type="edit" studentId={studentId} />

					<ConfirmationModal
						modalText={t("account.saveMessage")}
						handleFunction={handleSave}
						modalVisibility={modalVisibility}
						title=""
						changeModalVisibility={() => setModalVisibility(false)}
						spinning={spinning}
					/>
				</div>

				<div className="flex flex-col gap-4">
					<div className="flex flex-col gap-2">
						<Checkbox checked={checked} onChange={onCheckChange}>
							{t("modal.publicProfileAccept")}
						</Checkbox>

						<Checkbox
							checked={false} //</div></div>studentState?.sendNotifications}
							onChange={(e: CheckboxChangeEvent) => onNotificationToggle(e)}
						>
							{t("modal.receiveNotifications")}
						</Checkbox>

						<Checkbox
							disabled={!pushNotificationSupported}
							checked={userSubscription !== null}
							onChange={async (e: CheckboxChangeEvent) =>
								await onPushNotificationToggle(e)
							}
						>
							{t("modal.receivePushNotifications")}
						</Checkbox>
					</div>

					<Form.Provider onFormFinish={handleSave}>
						<Form form={form} layout={theme.layout} initialValues={state}>
							<div className="grid w-full grid-cols-1 md:grid-cols-2 gap-x-4">
								<Form.Item name="name" label={t("account.name") + ":"}>
									<Input
										disabled={true}
										onChange={(event: any) => handleChange(event, "name")}
									/>
								</Form.Item>

								<Form.Item
									name="email"
									label={t("account.contactEmail") + ":"}
									rules={[
										{
											required: false,
											message: t("account.requiredField", {
												field: t("account.contactEmail"),
											}),
											whitespace: true,
										},
										{
											type: "email",
											message: t("usersText.invalidField", {
												field: t("account.contactEmail"),
											}),
										},
									]}
								>
									<Input
										disabled={true}
										onChange={(event: any) => handleChange(event, "email")}
									/>
								</Form.Item>

								<Form.Item
									name="personalEmail"
									label={t("account.personalEmail") + ":"}
									rules={[
										{
											type: "email",
											message: t("usersText.invalidField", {
												field: t("account.email"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) =>
											handleChange(event, "personalEmail")
										}
									/>
								</Form.Item>

								<Form.Item
									name="phone"
									label={t("account.phone") + ":"}
									rules={[
										{
											pattern: phonePattern,
											message: t("usersText.invalidField", {
												field: t("account.phone"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) => handleChange(event, "phone")}
									/>
								</Form.Item>

								<Form.Item
									name="department"
									label={t("account.department") + ":"}
									rules={[
										{
											required: true,
											message: t("account.requiredField", {
												field: t("account.department"),
											}),
										},
									]}
								>
									<Select allowClear placeholder={t("account.department")}>
										{departmentList?.map((department) => (
											<Option key={department.id} value={department.id}>
												{i18n.language === "en"
													? department.departmentNameEn
													: department.departmentNameRo}
											</Option>
										))}
									</Select>
								</Form.Item>

								<Form.Item
									name="studyProgram"
									label={t("account.studyProgram") + ":"}
									rules={[
										{
											required: true,
											message: t("account.requiredField", {
												field: t("account.studyProgram"),
											}),
										},
									]}
								>
									<Select allowClear placeholder={t("account.studyProgram")}>
										{studyPrograms?.map((studyProgram: any) => (
											<Option key={studyProgram.id} value={studyProgram.id}>
												{studyProgram.name}
											</Option>
										))}
									</Select>
								</Form.Item>

								<Form.Item
									name="group"
									label={t("account.group") + ":"}
									rules={[
										{
											required: true,
											message: t("account.requiredField", {
												field: t("account.group"),
											}),
											whitespace: true,
										},
									]}
								>
									<Input
										onChange={(event: any) => handleChange(event, "group")}
									/>
								</Form.Item>

								<Form.Item
									name="cvUrl"
									label={t("account.cvUrl") + ":"}
									rules={[
										{
											whitespace: true,
										},
										{
											pattern: urlPattern,
											message: t("usersText.invalidField", {
												field: t("account.cvUrl"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) => handleChange(event, "cvUrl")}
									/>
								</Form.Item>

								<Form.Item
									name="gitUrl"
									label={t("account.githubLink") + ":"}
									rules={[
										{
											whitespace: true,
										},
										{
											pattern: urlPattern,
											message: t("usersText.invalidField", {
												field: t("account.githubLink"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) => handleChange(event, "gitUrl")}
									/>
								</Form.Item>
								<Form.Item
									name="linkedInUrl"
									label={t("account.linkedinLink") + ":"}
									rules={[
										{
											pattern: urlPattern,
											message: t("usersText.invalidField", {
												field: t("account.link"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) =>
											handleChange(event, "linkedInUrl")
										}
									/>
								</Form.Item>
							</div>
						</Form>
					</Form.Provider>
				</div>
			</div>

			<Form
				form={form}
				layout={theme.layout}
				initialValues={{ description: state?.description }}
				className="px-4"
			>
				<Form.Item name="skills" label={t("account.skills") + ":"}>
					<Select
						notFoundContent={t("tableText.noSkills")}
						mode="tags"
						maxTagCount="responsive"
						allowClear
						style={{ display: "inherit", borderRadius: "4px" }}
						value={skills}
						onChange={handleChangeSkills}
					>
						{userSkills}
					</Select>
				</Form.Item>

				<Form.Item
					name="description"
					label={t("account.description") + ":"}
					rules={[
						{
							whitespace: true,
						},
					]}
				>
					<CustomEditor
						content={state?.description}
						onEditorChange={(newDescription: string) =>
							setState({
								...state,
								description: newDescription,
							})
						}
					/>
				</Form.Item>
			</Form>
		</div>
	);
};

export default StudentProfile;
